export const createStylesForBreakpoints = (key, styles, theme) => {
  return {
    [theme.breakpoints.up('md')]: {
      [key]: styles.md,
    },
    [theme.breakpoints.up('lg')]: {
      [key]: styles.lg,
    },
  }
}

export const createSizesForBreakpoints = (sizes, theme) => {
  return createStylesForBreakpoints('width', sizes, theme)
}
