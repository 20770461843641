import React, { useState } from 'react'
import {
  Container,
  Grid,
  createStyles,
  withStyles,
  Typography,
} from '@material-ui/core'
import ScrollAnimation from 'react-animate-on-scroll'

import Feature from './Feature/index'
// import Calculator from './Calculator'
// import NetWorth from './NetWorth'
// import Settings from './Settings'
// import Lightbulb from './Lightbulb'
// import List from './List'
// import Calendar from './Calendar'
import Net_Worth from './Net_Worth.png'
import Savings_Plan from './Savings_Plan.png'
import Forecast from './Forecast.png'
import Investments from './Investments.png'
import Sync_Your_Accounts from './Sync_Your_Accounts.png'
import Transactions from './Transactions.png'
import House from './House.png'
import Emergency_Fund from './Emergency_Fund.png'
import Retirement from './Retirement.png'
import Refinance from './Refinance.png'
import networth from '../../images/networth-icon.png'
import savings from '../../images/savings-icon.png'
import financial from '../../images/financial-icon.png'
import investment from '../../images/investment-icon.png'
import sync from '../../images/sync-icon.png'
import transaction from '../../images/transaction-icon.png'
import house from '../../images/house-icon.png'
import emergency from '../../images/emergency-icon.png'
import retirement from '../../images/retirement-icon.png'
import refinance from '../../images/refinance-icon.png'
import active_arrow from './active-arrow.png'
import arrow from './arrow.png'
// import Net_Worth from './Net_Worth.png'
// import Net_Worth from './Net_Worth.png'

import { Link } from 'gatsby'

const styles = (theme) =>
  createStyles({
    section: {
      marginTop: '200px',
      [theme.breakpoints.up('lg')]: {
        marginTop: '200px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '200px',
      },
      [theme.breakpoints.up('xs')]: {
        marginTop: '5rem',
      },
    },
    container: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '77.625rem',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '58rem',
        padding: 0,
      },
      [theme.breakpoints.up('xs')]: {
        maxWidth: '77.625rem',
      },
    },
    gridItem: {
      [theme.breakpoints.up('xs')]: {
        margin: "0rem",
        padding: "0rem"
      },
      [theme.breakpoints.up('sm')]: {
        margin: "0 0.5rem",
        padding: "0rem"
      },
      [theme.breakpoints.up('md')]: {
        margin: "0 0.4rem",
        padding: "1rem"
      },
      [theme.breakpoints.up('lg')]: {
        margin: "-0.5rem 0.5rem",
        padding: "1rem"
      },
    },
    title: {
      marginBottom: '2.875rem',
      fontWeight: 800,
      lineHeight: '1.32',
      color: '#F0EDEF',
      [theme.breakpoints.up('md')]: {
        fontSize: '2.125rem',
        marginBottom: '3.0625rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '2.25rem',
        marginBottom: '2.375rem',
      },
    },
    link: {
      textDecoration: "none",
      margin: "1rem 0.7rem"
    },
    description: {
      // width: "20rem",
      // margin: "0 auto",
      [theme.breakpoints.up('xs')]: {
        // width:"12rem",
      },
      [theme.breakpoints.up('md')]: {
        width: '305px',
      },
      [theme.breakpoints.up('lg')]: {
        width: '305px',
      },
    },
    row: {
      '& + &': {
        [theme.breakpoints.up('md')]: {
          margin: '-1.5rem 0',
          // marginTop: '10rem',
        },
        [theme.breakpoints.up('lg')]: {
          margin: '-1.5rem 0',
        },
      },
    },
  })

const Features = ({ classes }) => {
  const [active, setActive] = useState(false)
  const FeaturesRow = (props) => (
    <Grid
      {...props}
      container
      justify="center"
      className={classes.row}
    />
  )
  return (
    <section className={classes.section}>
      <Container className={classes.container}>
        <ScrollAnimation animateIn="fadeIn" animateOnce>
          <Typography align="center" className={classes.title} variant="h3">
            Features Our Users Love
          </Typography>
          <FeaturesRow>
            <Grid item xs={12} sm={5} md={5} lg={5} className={classes.gridItem}>
              <Link to="/features/networth" className={classes.link}>
                <Feature
                  icon={networth}
                  arrow={active ? active_arrow : arrow}
                  setActive={setActive}
                  title="Net Worth"
                  featureImg={Net_Worth}
                  customStyles={{
                    textSizes: { md: '15rem', lg: '20rem' },
                  }}
                >
                  <Typography className={classes.description}>A comprehensive view of your current finances.</Typography>
                  {/* <img src={Net_Worth} alt="net-worth" /> */}
                </Feature>
              </Link>
            </Grid>

            <Grid item xs={12} sm={5} md={5} lg={5} className={classes.gridItem}>
              <Link to="/features/savings-plan" className={classes.link}>
                <Feature
                  icon={savings}
                  arrow={active ? active_arrow : arrow}
                  setActive={setActive}
                  title="Savings Plan"
                  featureImg={Savings_Plan}
                  customStyles={{
                    textSizes: { md: '15.1875rem', lg: '17rem' },
                  }}
                >
                  <Typography className={classes.description}>Create a savings plan and see what that means for your future.</Typography>
                  {/* <img src={Savings_Plan} alt="net-worth" /> */}
                </Feature>
              </Link>
            </Grid>
          </FeaturesRow>
          <FeaturesRow>
            <Grid item xs={12} sm={5} md={5} lg={5} className={classes.gridItem}>
              <Link to="/features/financial-future" className={classes.link}>
                <Feature
                  icon={financial}
                  arrow={active ? active_arrow : arrow}
                  setActive={setActive}
                  title="Financial Future"
                  featureImg={Forecast}
                  customStyles={{
                    textSizes: { md: '14rem', lg: '18rem' },
                  }}
                >
                  <Typography className={classes.description}>Your financial future with clear insights to help you plan.</Typography>
                  {/* <img src={Forecast} alt="net-worth" /> */}
                </Feature>
              </Link>
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={5} className={classes.gridItem}>
              <Link to="/features/investments" className={classes.link}>
                <Feature
                  icon={investment}
                  arrow={active ? active_arrow : arrow}
                  setActive={setActive}
                  title="Investments"
                  featureImg={Investments}
                  customStyles={{
                    textSizes: { md: '15.125rem', lg: '18.25rem' },
                  }}
                >
                  <Typography className={classes.description}>Track and analyze all your investments in one place.</Typography>
                  {/* <img src={Investments} alt="net-worth" /> */}
                </Feature>
              </Link>
            </Grid>
          </FeaturesRow>
          <FeaturesRow>

            <Grid item xs={12} sm={5} md={5} lg={5} className={classes.gridItem}>
              <Link to="/features/sync-accounts" className={classes.link}>
                <Feature
                  icon={sync}
                  arrow={active ? active_arrow : arrow}
                  setActive={setActive}
                  title="Sync Accounts"
                  featureImg={Sync_Your_Accounts}
                  customStyles={{
                    textSizes: { md: '14rem', lg: '18rem' },
                  }}
                >
                  <Typography className={classes.description}>Add all your accounts for a full financial picture.</Typography>
                  {/* <img src={Sync_Your_Accounts} alt="net-worth" /> */}
                </Feature>
              </Link>
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={5} className={classes.gridItem}>
              <Link to="/features/transactions" className={classes.link}>
                <Feature
                  icon={transaction}
                  arrow={active ? active_arrow : arrow}
                  setActive={setActive}
                  title="Transactions"
                  featureImg={Transactions}
                  customStyles={{
                    textSizes: { md: '15.125rem', lg: '18.25rem' },
                  }}
                >
                  <Typography className={classes.description}>Track all your transactions in one place.</Typography>
                  {/* <img src={Transactions} alt="net-worth" /> */}
                </Feature>
              </Link>
            </Grid>
          </FeaturesRow>
          <FeaturesRow>
            <Grid item xs={12} sm={5} md={5} lg={5} className={classes.gridItem}>
              <Link to="/features/house" className={classes.link}>
                <Feature
                  icon={house}
                  arrow={active ? active_arrow : arrow}
                  setActive={setActive}
                  title="House"
                  featureImg={House}
                  customStyles={{
                    textSizes: { md: '14rem', lg: '18rem' },
                  }}
                >
                  <Typography className={classes.description}>Understand your purchasing power to buy a house.</Typography>
                  {/* <img src={House} alt="net-worth" /> */}
                </Feature>
              </Link>
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={5} className={classes.gridItem}>
              <Link to="/features/emergency-fund" className={classes.link}>
                <Feature
                  icon={emergency}
                  arrow={active ? active_arrow : arrow}
                  setActive={setActive}
                  title="Emergency Fund"
                  featureImg={Emergency_Fund}
                  customStyles={{
                    textSizes: { md: '15.125rem', lg: '18.25rem' },
                  }}
                >
                  <Typography className={classes.description}>Know you have one in place and monitor it.</Typography>
                  {/* <img src={Emergency_Fund} alt="net-worth" /> */}
                </Feature>
              </Link>
            </Grid>
          </FeaturesRow>

          <FeaturesRow>
            <Grid item xs={12} sm={5} md={5} lg={5} className={classes.gridItem}>
              <Link to="/features/retirement" className={classes.link}>
                <Feature
                  icon={retirement}
                  arrow={active ? active_arrow : arrow}
                  setActive={setActive}
                  title="Retirement"
                  featureImg={Retirement}
                  customStyles={{
                    textSizes: { md: '14rem', lg: '18rem' },
                  }}
                >
                  <Typography className={classes.description}>Know what age and year you can realistically retire.</Typography>
                  {/* <img src={Retirement} alt="net-worth" /> */}
                </Feature>
              </Link>
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={5} className={classes.gridItem}>
              <Link to="/features/refinance" className={classes.link}>
                <Feature
                  icon={refinance}
                  arrow={active ? active_arrow : arrow}
                  setActive={setActive}
                  title="Refinance"
                  featureImg={Refinance}
                  customStyles={{
                    textSizes: { md: '15.125rem', lg: '18.25rem' },
                  }}
                >
                  <Typography className={classes.description}>Connect your loans to know if you can save money by refinancing.</Typography>
                  {/* <img src={Refinance} alt="net-worth" /> */}
                </Feature>
              </Link>
            </Grid>
          </FeaturesRow>
        </ScrollAnimation>
      </Container>
    </section>
  )
}

export default withStyles(styles)(Features)
