import React, { Component } from 'react'
import { createStyles, Typography, withStyles, Paper } from '@material-ui/core'
import { createSizesForBreakpoints } from '../../../utils/sizes'
import active_arrow from '../active-arrow.png'
import arrow from '../arrow.png'

const createFeatureStyles = ({ textSizes }) => (theme) => {
  const padding = '0 1.875rem'
  const textSizesForBreakpoints = createSizesForBreakpoints(textSizes, theme)

  const breakpointUpLg = theme.breakpoints.up('lg')
  const breakpointUpMd = theme.breakpoints.up('md')

  return createStyles({
    section: {
      marginBottom: '2.3125rem',
      textAlign: 'left',
      listStyle: 'none',
      position: "relative",
      // width: '100%',
      [breakpointUpMd]: {
        width: 'auto',
        // padding: 0,
      },
      [theme.breakpoints.up('xs')]: {
        // paddingLeft:"2.5rem",
        // padding: "0px",
        // margin: "0px",
        width: 'auto',
      },
      [theme.breakpoints.up('sm')]: {
        // paddingLeft:"2.5rem",
        // padding: "0px",
        // margin: "0px",
        // width: '18.5625rem',
      },
      [theme.breakpoints.up('md')]: {
        // width: '18rem',
        // marginRight: '1.5rem',
        width: 'auto!important',
        paddingLeft: "4rem",
      },
      [theme.breakpoints.up('lg')]: {
        width: 'auto',
        paddingLeft: "4rem",
        // width: '28rem',
        // marginRight: '4.4375rem',
      },
      '&:first-of-type': {
      },
      '&:nth-of-type(2n)': {
        [theme.breakpoints.up('md')]: {
          width: '19.875rem',
          marginRight: '3.3125rem',
        },
        [theme.breakpoints.up('lg')]: {
          width: '24.5625rem',
        },
      },
      // '&:last-of-type': {
      //   [theme.breakpoints.up('lg')]: {
      //     width: '23.5625rem',
      //   },
      // },
    },

    paper: {
      padding: "10px",
      // margin: "1em 0",
      backgroundColor: '#101010',
      height: "85%",
      borderRadius: "6px",
      // border: "1px solid #EEF1F5",
      // width:"100%",

      '&:hover': {
        backgroundColor: '#2B2B2B',
        boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.1)',
        // boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)",
        '& .arrowIcon': {
          color: "#FF0080",
          // right:"4px",
          transform: "translateX(5px)"
        }
      },
      [theme.breakpoints.up('sm')]: {
        height: '315px',
      },
      [theme.breakpoints.up('md')]: {
        height: '405px',
      },
    },
    header: {
      // marginBottom: '1.375rem',
      margin: '1.375rem 0',
      [theme.breakpoints.up('xs')]: {
        paddingLeft: "2.5rem",
        // paddingTop:"1rem"
        // width:"9rem",
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: "0",
        // height: '3.3637rem',
        // width:"15rem",
      },
      [theme.breakpoints.up('lg')]: {
        // width:"20rem",
      },


      // width:"20rem",
      // margin:"0 auto",
      // display: "flex",
      // flexDirection: "row",
      // justifyContent: "space-between"
    },
    title: {
      // marginBottom: '1.375rem',
      // margin: '1.375rem auto',
      // fontSize: "1em",
      color: "#F0EDEF",
      [theme.breakpoints.up('xs')]: {
        fontSize: "22px",
        fontWeight: "700",
      },
      [theme.breakpoints.up('md')]: {
        // fontSize: "1.5em",
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: "34px",
        fontWeight: "700"
      },

    },
    content: {
      // fontSize: '0.875rem',
      [theme.breakpoints.up('xs')]: {

        fontSize: '16px',
        paddingLeft: "2.5rem",
        paddingRight: "0.2rem",
      },
      [theme.breakpoints.up('md')]: {
        // fontSize: "1.5em",
        paddingLeft: "0",
      },
      [theme.breakpoints.up('lg')]: {
        paddingRight: "2rem",
        // fontSize: "34px",
        // fontWeight:"700"
      },
      // margin: '0 auto',
      // ...textSizesForBreakpoints,
      // [breakpointUpLg]: {
      //   ...textSizesForBreakpoints[breakpointUpLg],
      //   fontSize: '1rem',
      // },
    },
    featureImg: {
      width: "100%",
      [theme.breakpoints.up('xs')]: {
        width: "105%",
      },
      [theme.breakpoints.up('md')]: {
        width: "100%",
      },
      [theme.breakpoints.up('lg')]: {
        width: "100%",
      }
    },
    icon: {
      position: "absolute",
      left: "10px",
      width: "30px",
      // height: "30px",
      [theme.breakpoints.up('xs')]: {
        width: "30px",
        top: "0px",
        left: '0px',
      },
      [theme.breakpoints.up('sm')]: {
        top: "-5px"
      },
      [theme.breakpoints.up('md')]: {
        left: "20px",
        width: "30px",
        top: '6px',
      },
      [theme.breakpoints.up('lg')]: {
        left: "20px",
        width: "30px",
        top: '6px',
      },
    },
    arrow: {
      position: "absolute",
      right: "10px",
      width: "30px",
      height: "30px",
      color: "#C8D2E1",
      transition: 'transform 0.3s ease',
      transform: 'translateX(0px)',
      '&:hover': {
        color: "#FF0080"
      },
      [theme.breakpoints.up('lg')]: {
      },
      [theme.breakpoints.up('xs')]: {
        right: "0px",
      },
      [theme.breakpoints.up('md')]: {
        right: "7px"
      },
      [theme.breakpoints.up('lg')]: {
        right: "10px",
        width: "35px",
        height: "35px",
      },
    }
  })
}

class Feature extends Component {
  render() {
    const { classes, children, title, icon, featureImg, arrow, setActive } = this.props
    return (
      <div className={classes.paper}
      // onMouseEnter={() => setActive(true)}
      // onMouseLeave={() => setActive(false)}
      >
        <div className={classes.section}>
          <img src={icon} alt="icon" className={classes.icon} />
          <span className={`${classes.arrow} arrowIcon`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
          </span>
          {/* <img src={arrow} alt="arrow" className={classes.arrow} /> */}
          <div className={classes.header}>
            <Typography variant="h3" className={classes.title}>
              {title}
            </Typography>
            {/* {icon} */}
          </div>
          <div className={classes.content}>{children}</div>
          <div>
            <img className={classes.featureImg} src={featureImg} alt={title} />
          </div>
        </div>
      </div>
    )
  }
}

const FeatureWithStyles = ({ customStyles, ...other }) => {
  const styles = createFeatureStyles(customStyles)
  const FeatureWithCustomClasses = withStyles(styles)(Feature)
  return <FeatureWithCustomClasses {...other} />
}

export default FeatureWithStyles

// const data = [
//   {
//       name: 'SATO/BTC',
//       last: 0.00000001,
//       change: '+0.00%',
//       high: 0.00000001,
//       low: 0.00000001,
//       volume: 0.00007201,
//       price_precision: 6
//   },
//   {
//       name: 'SATO/DASH',
//       last: 0.00000000,
//       change: '+0.00%',
//       high: 0.00000000,
//       low: 0.00000000,
//       volume: 0.03400000,
//       price_precision: 6
//   },
//   {
//       name: 'SATO/DOGE',
//       last: 0.00017000,
//       change: '+0.00%',
//       high: 0.00000000,
//       low: 0.00000000,
//       volume: 45.00000000,
//       price_precision: 6
//   },
//   {
//       name: 'SATO/USDT',
//       last: 0.00009350,
//       change: '+0.00%',
//       high: 0.00000000,
//       low: 0.00000000,
//       volume: 33.00000000,
//       price_precision: 6
//   },
//   {
//       name: 'CDY/TRX',
//       last: 0.00000000,
//       change: '+0.00%',
//       high: 0.00000000,
//       low: 0.00000000,
//       volume: 0.00000030,
//       price_precision: 6
//   },
//   {
//       name: 'LTC/BTC',
//       last: 0.00000000,
//       change: '+0.00%',
//       high: 0.00000000,
//       low: 0.00000000,
//       volume: 0.00000022,
//       price_precision: 6
//   },
//   {
//       name: 'NAH/BTC',
//       last: 0.00000018,
//       change: '+0.00%',
//       high: 0.00000000,
//       low: 0.00000000,
//       volume: 11.00000000,
//       price_precision: 6
//   },
//   {
//       name: 'RDD/BTC',
//       last: 0.00000004,
//       change: '+0.00%',
//       high: 0.00000000,
//       low: 0.00000000,
//       volume: 2.00000000,
//       price_precision: 6
//   },
//   {
//       name: 'RDD/TRX',
//       last: 0.03000000,
//       change: '+0.00%',
//       high: 0.00000000,
//       low: 0.00000000,
//       volume: 1.00000000,
//       price_precision: 6
//   },
//   {
//       name: 'RVN/BTC',
//       last: 0.00000000,
//       change: '+0.00%',
//       high: 0.00000000,
//       low: 0.00000000,
//       volume: 0.00030000,
//       price_precision: 6
//   },
//   {
//       name: 'WDC/LTC',
//       last: 0.00036400,
//       change: '+0.00%',
//       high: 0.00000000,
//       low: 0.00000000,
//       volume: 0.00002400,
//       price_precision: 6
//   },
//   {
//       name: 'CDY/DOGE',
//       last: 1.00000000,
//       change: '+0.00%',
//       high: 0.00000000,
//       low: 0.00000000,
//       volume: 0.00002000,
//       price_precision: 6
//   }]